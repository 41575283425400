import React, { useEffect } from "react";
import 'locomotive-scroll/dist/locomotive-scroll.css';

export default function Home() {
    const [menuOpener, setMenuOpener] = React.useState(false);
   
    useEffect(() => {
        ; (async () => {
            try {
                const LocomotiveScroll = (await import('locomotive-scroll')).default
                const dataScrollContainer = document.querySelector('[data-scroll-container]',)
                if (!dataScrollContainer) {
                    console.warn('locomotive-scroll: [data-scroll-container] dataset was not found. You likely forgot to add it which will prevent Locomotive Scroll to work.',)
                }
                window.locomotive = new LocomotiveScroll({
                    el: dataScrollContainer ?? undefined,
                    smooth: true,
                    multiplier: 3,
                })
            } catch (error) { }
        })()

        return () => { window.locomotive?.destroy() }
    }, [])

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        console.log("scrolling to top");
        window.locomotive.scrollTo('top');
    };

    return (
        <div className="fixed flex flex-col gap-3">
            {/* navbar */}
            <div className='fixed z-40 flex flex-row justify-between w-full h-12 p-2 mix-blend-screen'>
                <div className="container flex flex-row items-center justify-between">
                    <h1 className="flex items-center gap-2" >
                        <div onClick={() => scrollToTop()} className="flex items-center gap-2 font-bold tracking-tight text-gray-700 uppercase">Katherine Velasquez</div>
                    </h1>
                    <div onClick={() => { setMenuOpener(!menuOpener) }} className="text-xs font-bold text-gray-700 uppercase">{menuOpener ? 'Close' : 'PORTAFOLIO'}</div>
                </div>
            </div>

            <div className="flex flex-col" data-scroll-container>
                {/* header */}
                <div className="flex bg-right bg-no-repeat bg-contain"
                    data-scroll-section>
                    <div className="container flex flex-col items-start justify-center px-4 h-[800px]  min-h-[700px] md:h-screen gap-4 bg-cover" >
                        <div className="mt-8" data-scroll data-scroll-speed="2" data-scroll-repeat="true">
                            <img src="/profile_katty.jpg" alt="Katherine Velasquez" className="w-48 h-48 rounded-full" />
                        </div>
                        <div data-scroll data-scroll-speed="1" data-scroll-repeat="true" className="flex flex-col gap-2 text-5xl tracking-tight">
                            <div className="text-4xl md:text-7xl font-extralight text-primary">Graphic Designer + Art Director <br /> Digital Product Lead</div>
                            <div className="text-3xl tracking-normal md:text-5xl text-neutral-600 font-extralight">And growth hacking practicionner</div>
                        </div>
                        <a className="p-2 px-4 mt-8 font-semibold tracking-wide text-white transition-all bg-black hover:scale-110 hover:bg-primary" href="https://wa.me/51993247532">Whatsapp</a>
                        <a className="mt-4 text-xs font-bold underline text-primary" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/katherine-velasquez-castro/">Perfil Linkedin</a>
                    </div>
                </div>


                {/* promise */}
                <div data-scroll-section className="flex w-full text-7xl bg-primary text-white items-center justify-center px-[10%] h-screen ">
                    <div data-scroll data-scroll-speed="2" data-scroll-repeat="true" data-scroll-direction="horizontal" className="font-extralight" >
                        Diseño integral / Data / Tecnología / Human Centered
                    </div>
                </div>

                {/* experiencia */}
                <div className="container z-10 flex flex-col gap-2 p-12 bg-white snap-center" data-scroll-section>
                    <div className="text-xs font-black tracking-widest uppercase">Work</div>
                    <div className="text-4xl font-bold leading-10 tracking-tighter uppercase">
                        AB inBev Chile · Infinito Consultores · Gamestate · Inspiral Growth · TOULOUSE-LAUTREC · ISIL · MUSEO PEDRO DE OSMA · ICONO · PRESTAMYPE · AREA51 · MAQUINARIAS ·
                    </div>
                    <div>y más de 30 marcas en 5 paises</div>
                </div>

                {/* tech */}
                <div className="container flex flex-col pt-12 md:flex-row snap-center" data-scroll-section>

                    <div className="flex flex-col gap-2 text-white p-14 bg-primary" data-scroll data-scroll-direction="horizontal" data-scroll-speed="1" >
                        <div className="mb-4 font-black tracking-widest text-s">
                            <div className="uppercase ">Development</div>
                            <div className="tracking-normal text-m" >Diseño para Usuarios finales basado en tecnología y data:</div>
                        </div>
                        <div className="mb-4 text-3xl font-bold tracking-tight">React · Strapi · Express · PostgreSQL · Node.js · PaaS · CI/CD · UX/UI · Figma · Sketch · Adobe Creative Suite · Final Cut · GTM · GA· Google optimize · Git</div>
                        <div className="font-bold text-xxs opacity-70">_</div>
                    </div>

                    {/* capital */}
                    <div className="flex flex-col justify-center gap-2 p-12 bg-black text-primary" data-scroll data-scroll-speed="1" data-scroll-repeat="true">
                        <div className="text-xs font-black tracking-widest text-white uppercase">CAPITAL INTELECTUAL</div>
                        <div className="text-2xl font-bold tracking-tight">Kingdom Element design studio</div>
                        <a className="hover:animate-pulse" target="_blank" rel="noreferrer" href="https://kingdomelement.com/">
                            <img src="https://kingdomelement.com/wp-content/uploads/2020/02/KGDM_logo_red.png" alt="Kingdom Element" />
                        </a>
                        <div className="text-3xl font-bold tracking-tight"></div>
                    </div>

                </div>

                {/* producciones */}
                <div className="flex flex-col max-w-screen snap-center" data-scroll-section>
                    <div className="container">
                        <div className="py-12 text-2xl font-black tracking-widest text-center text-black uppercase">Portfolio</div>
                    </div>
                    <div className="flex flex-col gap-4 py-12">

                        <div className="flex flex-col gap-4 md:flex-row">
                            <div data-scroll data-scroll-speed="0" className="flex md:w-2/3 md:ml-8 bg-no-repeat bg-contain aspect-[1920/1080]" style={{backgroundImage:'url(mockup_web_kath.png)'}}>
                                {/* <img src="mockup_web_kath.png" alt="Catalogo ABinBev Chile" /> */}
                                </div>
                            <div className="flex flex-col gap-4 md:w-1/3">
                                <div data-scroll data-scroll-speed="1" className="flex mr-16 text-primary aspect-video">
                                    <div className="flex flex-col gap-2 p-8 md:justify-center md:p-12 text-primary" data-scroll data-scroll-speed="1" data-scroll-repeat="true">
                                        <div className="text-xs font-black tracking-widest text-black uppercase">from Excel to app</div>
                                        <div className="font-black tracking-widest text-black text-s">UX/UI <br />ReactJS - GA4/GTM <br />CRM for data management</div>
                                        <div className="text-3xl font-bold tracking-tight">Catálogo AB inBev Chile</div>
                                    </div>
                                </div>
                                <div data-scroll data-scroll-speed="2" className="flex md:ml-8 bg-primary bg-no-repeat bg-contain aspect-[1920/1080]" style={{backgroundImage:'url(StrapiAB.png)'}}> 
                                {/* <img src="StrapiAB.png" alt="Excel" /> */}
                                 </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 py-12 bg-gray-200 md:flex-row">
                            <div className="flex flex-col gap-4 md:w-1/3">
                                <div data-scroll data-scroll-speed="1" className="flex mr-16 text-primary aspect-video">
                                    <div className="flex flex-col justify-center gap-2 p-12 text-primary" data-scroll data-scroll-speed="1" data-scroll-repeat="true">
                                        <div className="text-xs font-black tracking-widest text-black uppercase">Claw plush App</div>
                                        <div className="font-black tracking-widest text-black text-s">UX/UI <br /> Figma design</div>
                                        <div className="text-3xl font-bold tracking-tight">Gamestate App</div>
                                    </div>
                                </div>
                                {/* <div data-scroll data-scroll-speed="2" className="flex md:ml-8 bg-primary aspect-video"> <img src="https://kgdm.live/assets/3_bp7pva.jpg" alt="Excel" /> </div> */}
                            </div>
                            <div data-scroll data-scroll-speed="0" className="flex md:w-2/3 md:ml-8 aspect-text"> <img src="GS_1.jpg" alt="Gamestate App" /> </div>
                        </div>

                        <div className="flex flex-col gap-4 py-12 md:flex-row">
                            <div data-scroll data-scroll-speed="0" className="flex md:w-2/3 md:ml-8 bg-center bg-no-repeat bg-contain aspect-[1920/1080]" style={{backgroundImage:'url(NorisApp.jpg)'}}>
                                 {/* <img src="NorisApp.jpg" className="flex aspect-[1920/1080]" alt="Delivery App" /> */}
                                  </div>
                            <div className="flex flex-col gap-4 md:w-1/3">
                                <div data-scroll data-scroll-speed="1" className="flex mr-16 text-primary aspect-video">
                                    <div className="flex flex-col justify-center gap-2 p-12 text-primary" data-scroll data-scroll-speed="1" data-scroll-repeat="true">
                                        <div className="text-xs font-black tracking-widest text-black uppercase">Japanese Delivery App</div>
                                        <div className="font-black tracking-widest text-black text-s">UX/UI <br /> Figma Design <br />CRM for data management</div>
                                        <div className="text-3xl font-bold tracking-tight">Delivery App</div>
                                    </div>
                                </div>
                                <div data-scroll data-scroll-speed="2" className="flex md:ml-8 bg-primary aspect-video"> <img src="norisFlow.jpg" alt="Japanese Delivery App" /> </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* footer */}
                <div className="container flex flex-col gap-2 p-4 py-12 text-xs font-semibold tracking-tight text-gray-600" data-scroll-section>
                    <div className="flex font-bold uppercase tet-xxs ">Katherine Velasquez</div>
                    <div className="w-2/3 leading-4 text-gray-500 text-xxs">Propiedad intelectual reservada</div>
                </div>

            </div>

            <div className={` duration-1000 text-lg gap-3 font-bold absolute bg-primary text-white overflow-hidden z-30 flex-col flex items-center justify-center transition-all w-screen ${menuOpener ? "h-screen" : "h-0"} `}>
            <div><a className="transition-all duration-1000 transform hover:text-black" target="_blank" rel="noreferrer" href="https://bit.ly/KatherineVelasquezPortfolio">Portafolio</a></div>
            <div><a className="transition-all duration-1000 transform hover:text-black" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/katherine-velasquez-castro/">Linkedin</a></div>
            <div><a className="transition-all duration-1000 transform hover:text-black" target="_blank" rel="noreferrer" href="https://bit.ly/CVKatherineVelasquez">Curriculum</a></div>
            </div>
        </div>
    );
}